import { render, staticRenderFns } from "./myhead.vue?vue&type=template&id=fb87b864&scoped=true"
import script from "./myhead.vue?vue&type=script&lang=js"
export * from "./myhead.vue?vue&type=script&lang=js"
import style0 from "./myhead.vue?vue&type=style&index=0&id=fb87b864&prod&scoped=scoped&lang=css"
import style1 from "./myhead.vue?vue&type=style&index=1&id=fb87b864&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb87b864",
  null
  
)

export default component.exports