//韩语
export default {
 tip:{
  fromSuccess:'제출 성공'
 },
 login:{
   linkList:[{name:'별빛 쇼핑센터',url:'/'},{name:'거래원으로 등록하다',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}],
   registered:{
  text:'계좌가 없습니까?등록 >> 클릭',
  url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'
   },
   shouye:{
     url:'/'
   },
  forgot : '비밀번호를 잊어버렸어요? ',
  title : '계정 로그인 ',
  email : '메일박스 ',
  pwd : '은어 ',
  denglu : '로그인 ',
  mima : '비밀번호를 잊어버리다 ',
  Reset : '암호 재설정 ',
  p_email : '이메일을 입력하십시오. ',
  p_pwd : '암호를 입력하십시오. ',
  p_new_pwd : '암호를 입력하십시오. ',
  z_email : '정확한 이메일 주소를 입력하십시오. ',
  long : '6~18자 길이 ',
  determine : '결정 ',
  Valid : '암호 재설정 응용 프로그램이 커밋되었습니다.전자 메일을 확인하고 링크 재설정을 확인하십시오.링크는 24시간 동안 유효합니다! ',
  Help_reset : '등록 전자 메일 주소를 입력하면 암호를 재설정할 수 있습니다. ',

  },
 myhead:{
  loginName : '로그인 ',
  Personalcenter : '개인 센터 ',
  Placeholder : '입력하십시오... ',
  myhead_a : '집. ',
  myhead_b : '거래 ',
  myhead_bc : '거래 플랫폼',
  myhead_c : '플랫폼 ',
  myhead_d : '도움말 및 리소스 ',
  myhead_e : '우리에 관하여 ',
  myhead_f : 'Starter X 도움말 ',
  myhead_g : '최고의 거래자가 되다 ',
  myhead_h : '거래를 시작하다 ',
  myhead_i : '무료 프레젠테이션 시도 ',

  myhead_j:{
    text:'시장 활동',
    url:'https://www.interstellarfx.net/zh/trade-cashback'
  },
  myhead_k : '거래 현금 반환 ',
  myhead_l : '시장 ',
  myhead_m : '무역 관련 ',
  myhead_n : '외화 학교 ',
  myhead_o:'추석 포인트 보상 이벤트',
  myhead_p:'인터스텔라 공지사항',
  myhead_q:'시장 예측',
  
  myhead_r:'CFD는 레버리지 특성으로 인해 빠른 손실 위험이 높은 복잡한 금융 거래 상품입니다. 대부분의 개인 투자자는 CFD를 거래할 때 돈을 잃습니다. 사용자는 CFD가 어떻게 작동하는지 완전히 이해하고 재정적 손실의 높은 위험을 감당할 수 있는지 판단해야 합니다.',
  myhead_s:'회사',
  myhead_t:'시장',
  myhead_u:'오퍼',
  myhead_v:'분석 및 교육',
  myhead_v_a:' 분석',
  myhead_v_b:' 교육',
  myhead_w:'감독',
  myhead_x:'보험',
  myhead_y:'법률 준수',
  myhead_z:'최신 소식',
  myhead_aa:'거래 안내',
  myhead_bb:'거래 용어집',
  myhead_cc:'실시간 뉴스',
  myhead_dd:'거래 분석',
  myhead_ee:'외환 투자',
  myhead_ff:'후원 & 사회적 책임',
  myhead_gg:'ISG 웹 세미나',
  myhead_hh:'FISG 분석',
  myhead_ii:'트레이딩 코스',
  myhead_com:'',
  
 },
 Home:{
  Home_a : '별점 쇼핑몰 ',
  Home_b : '선물 거래처! ',
  Home_c : '좋은 선물, 패션 디지털, 정교한 전기, 최고급 호화 자동차와 수시로 집으로 가져가는 좋은 물건들의 거래처... ',
  Home_d : '적용 가능한 활동 약관 및 규정 보기 ',
  Home_e : '클릭 ',
  Home_f : '여기 있다 ',
  Home_g : '좋은 선물, 기다릴게요. ',
  Home_h : '원하는 선물이 없어요? ',
  Home_i : '당신이 언제든지 우리에게 당신이 원하는 제품을 추천하는 것을 환영합니다. 우리는 추천 선물의 유행 정도에 따라 제품을 보충할 것입니다... ',
  Home_j : '선물 브랜드 ',
  Home_k : '상품 설명 ',
  Home_l : '굴복하다 ',
  Home_m : '보상 포인트 선물 교환 규칙 ',
  Home_n : '어떻게 포인트를 획득합니까 ',
  Home_o : '우리의 플랫폼에서 화폐 쌍, 귀금속과 에너지 제품을 거래하는 성간 외환 고객은 매번 1개의 신용을 얻을 수 있다. ',
  Home_p : '어떻게 선물을 교환합니까 ',
  Home_q : '포인트 상점에서 거래 신청(당신의 성명, 연락처, 거래 계정 등 정보를 기입)을 제출하면 백그라운드에서 당신의 포인트와 최근 불법 거래가 있는지 검사하고 통과 후 분배를 심사합니다. ',
  Home_r : '선물 시간 ',
  Home_s : '선물은 교환 신청이 비준을 받은 후 15일 이내에 배달됩니다. 검사가 필요하면 고객 서비스 매니저에게 연락하여 납품 진도를 검사할 수 있습니다. ',
  Home_t : '판매 후 설명 ',
  Home_u : '포인트 상점에 열거된 모든 상품은 제3자 공급업체가 제공하며 불합리한 반품은 지원하지 않습니다.단, 택배 과정에서 잘못된 선물이나 파손이 발생하면 단독 고객 서비스 부서에 연락하여 문제를 해결할 수 있습니다. ',
  Home_v : '대중 추천 ',
  Home_w : '가장 인기 있는 트렌드 상품이 기다리고 있습니다. ',
  Home_x : '변환 ',
  Home_y : '요점 ',
  Home_z : '브랜드 선택 ',
  Home_aa : '신제품 어레이 ',
  Home_bb : '점 정렬 ',
  Home_cc : '브랜드 선택 ',
  Home_dd : '오직 ',
  Home_ee : '포인트 교환 가능 ',
  Home_ff : '선물 브랜드 입력 ',
  Home_gg : '제품 설명을 입력하십시오. ',
  Home_hh : '조항 ',
  Home_ii : '성공 ',
  Home_zz : '전체 쇼핑몰 ',
  Home_kk : '포인트 몰 ',
  Home_search:'상품 검색'

 },
 detail:{
  detail_a : '쇼핑몰 홈 페이지로 돌아가기 ',
  detail_b : '모델 ',
  detail_c : '숫자 ',
  detail_d : '선물 교환 설명 ',
  detail_e : '선물은 교환 신청이 승인된 후 15일 이내에 교부됩니다. 검사가 필요하면 전문 고객 서비스 매니저에게 연락하여 교부 진도를 검사할 수 있습니다. ',
  detail_f : 'Integrpoint 쇼핑몰에 나열된 모든 상품은 제3자 공급업체가 제공하며 무단 반품은 지원되지 않습니다.단, 택배 과정에서 잘못된 선물이나 파손이 발생하면 단독 고객 서비스 부서에 연락하여 문제를 해결할 수 있습니다. ',
  detail_g : '제품 상세 정보 ',
  detail_h : '대중 추천 ',
  detail_i : '로그인 ',

 },
 settlement:{
  balance:'마일리지 잔액',
  settlement_a : '주문 정보 ',
  settlement_b : '이름 ',
  settlement_c : '규격 ',
  settlement_d : '단가 ',
  settlement_e : '숫자 ',
  settlement_f : '수신자 정보 ',
  settlement_g : '주소 추가 ',
  settlement_h : '결제 금액 ',
  settlement_i : '요점 ',
  settlement_z : '즉시 해결 ',
  settlement_k : '성공적으로 제출 ',
  settlement_l : '주문서를 확인하십시오!',
  settlement_m:'상품',
  settlement_n:'상품 정보!',
  settlement_o:'포인트 정보!',
  settlement_p:'현재 필요한 포인트 부족',
  settlement_title : '성공을 위하여 ',
  settlement_tip : '당신의 교환 주문서는 15일 이내에 배달될 것입니다.또한 전담 고객 서비스 관리자에게 문의하여 배송 진행 상황을 확인할 수도 있습니다. ',
  settlement_button : '결정 ',
  settlement_tip_error:'포인트 획득 방법:거래한 화폐쌍, 귀금속 및 에너지제품의 매 거래마다 1포인트 획득 (3분 이상 포지션 중인 주서는 유효함)',
 },
 meber:{
  meber_a : '주문 센터 ',
  meber_b : '개인 센터 ',
  meber_c : '나의 명령 ',
  meber_d : '주소 관리 ',
  meber_e : '로그아웃 ',

 }, 
 
 address:{
  address_a : '늘다 ',
  address_b : '이름 ',
  address_c : '호출 ',
  address_d : '주소 ',
  address_e : '활용단어참조 ',
  address_f : '편집하다 ',
  address_g : '삭제하다 ',
  address_h : '선택하십시오. ',
  address_i : '자세한 주소 ',
  address_j : '구원하다 ',
  address_k : '이름 입력 ',
  address_l : '전화번호를 입력하십시오 ',
  address_m : '전화 번호가 올바르지 않습니다. ',
  address_n : '전화번호를 입력하십시오 ',
  address_o : '전체 주소를 입력하십시오. ',
  address_p : '수정 성공 ',
  address_q : '추가 성공 ',
  address_r : '삭제 성공 ',
  address_s : '새 수확 주소 ',
  address_t : '배송 주소를 추가하지 않았습니다. ',

 },
 
 order:{
  order_a : '나의 명령 ',
  order_b : '날짜 ',
  order_c : '주문 번호 ',
  order_d : '받는 사람 ',
  order_e : '수량 ',
  order_f : '주문 상세 정보 ',
  order_g : '호출 ',
  order_h : '주소 ',
  order_i : '상태 ',
  order_j : '운송 방식 ',
  order_k : '활용단어참조 ',
  order_l : '닫다 ',
  order_m : '만들다 ',
  order_n : '지불하다 ',
  order_o : '환불 중 ',
  order_p : '환불 ',
  order_q : '선적 ',
  order_r : '완성 ',
  order_s : '원가 포인트 ',  
 },
 
 userinfo:{
  userinfo_a : '개인 센터 ',
  userinfo_b : '마지막 업데이트 시간 ',
  userinfo_c : '사용자 메일박스 ',
  userinfo_d : '가용 학점 ',

 },
 
 orderDetail:{
  orderDetail_a : '주문 제출 ',
  orderDetail_b : '결제 성공 ',
  orderDetail_c : '항목별로 ',
  orderDetail_d : '납품을 기다리다 ',
  orderDetail_e : '완성 ',
  orderDetail_f : '지불 방식 ',
  orderDetail_g : '택배원 이름 ',
  orderDetail_h : '조회 번호 ',

 },
 
 footerbox:{ 
  footerbox_b : '오늘날의 글로벌 시장 ',
  footerbox_c : '무료 프레젠테이션 시도 ',
  footerbox_d : '아시아 태평양 고객 서비스(싱가포르) ',
  footerbox_e : '회사 이메일 ',
  footerbox_f : 'support@interstellarsfx.com ',
  footerbox_g : '거래 시작 ',
  footerbox_h : '외화 ',
  footerbox_i : '중요한 금속 ',
  footerbox_ik : '재고',
  footerbox_j : '에너지 ',
  footerbox_k : '지수 ',
  footerbox_a : '거래에 관하여 ',
  footerbox_l : '계정 ',
  footerbox_m : '계정 유형 ',
  footerbox_ml : '레버리지 정책',
  footerbox_n : '입금 및 출금',
  footerbox_o : '거래를 시작하다 ',
  footerbox_p : '무료 프레젠테이션 시도 ',
  footerbox_q : '도구 ',
  footerbox_r : 'MetaTrader 4 ',
  footerbox_s : 'MAM ',
  footerbox_t : 'MultiTerminal',
  footerbox_u : '경제 캘린더',
  footerbox_v : '외화 계산기 ',
  footerbox_w : '부총재 ',
  footerbox_x : '무역 센터 ',
  footerbox_y : '직업학교 ',
  footerbox_z : '대회라는 단어 ',
  footerbox_aa : 'InterStellar 정보',
  footerbox_ad : '회사',
  footerbox_bb : '소식 ',
  footerbox_cc : '사업 ',
  footerbox_dd : '연락처 ',
  footerbox_ee : '협력 관계 ',
  footerbox_ff : '자주하는 질문',
  
  footerbox_mm : '면책 조항:  ',
  footerbox_nn : `웹 사이트 www.interstellarsfx.com은 세이셸 법률 등록에 따라 설립된 유한책임회사인 First Interstellar Global Ltd가 운영하며 등록 사무실 주소는 Room 9, Deenu's Building, Providence, Mahe, Seychelles.First Interstellar Global Ltd。First Interstellar Global Ltd는 세이셸 금융 서비스 관리국의 승인 및 규제이며 규제 번호는 SD127입니다.First Interstellar Global Ltd는 유럽 금융 규제 프레임워크에 속하지 않으며, 금융 수단 시장 지침(MiFID) II 및 기타 유럽 금융 서비스 규정은 First Interstellar Global Ltd.가 제공하는 금융 서비스에 적용되지 않으며 투자자 보상 프로그램에 대한 규정이 없습니다. `,
  footerbox_gg : 'InterstellarFX는 미국, 이란, 캐나다, 벨기에 및 프랑스를 포함하여 일부 관할 지역의 거주자에게 차액 계약 거래를 제공하지 않습니다.',
  footerbox_hh : '위험 경고: ',
  footerbox_ii : `외환과 레버리지 금융 품목을 거래하는 것은 높은 위험을 가지고 있으며 원금 손실을 초래할 수 있습니다.귀하가 부담하는 손실 위험은 귀하의 감당 능력을 초과해서는 안 됩니다. 귀하가 그 위험에 대해 완전히 이해하도록 하십시오.거래 레버리지 상품은 모든 투자자에게 적합하지 않다.주식과 같은 비레버리지 상품을 거래하는 것도 위험과 관련된다. 왜냐하면 주식의 가치는 상승할 수도 있고 하락할 수도 있기 때문이다. 이것은 당신이 회수한 자금이 당신의 초기 투입보다 낮을 수 있다는 것을 의미한다.과거의 표현은 결코 미래의 결과를 보장할 수 없다.거래 전에 경험 수준, 투자 목표를 고려하고 필요한 경우 독립적인 재무 조언을 구하십시오.고객은 자신이 거주하는 국가의 법률이 스타 브랜드가 제공하는 서비스를 사용할 수 있도록 허용할 책임이 있습니다.`,
  footerbox_zz : '위험 성명 ',
  footerbox_kk : '프라이버시 정책 ',
  footerbox_ll:'Copyright © 2021 Interstellar FX. All rights reserved.',
 }
 
}